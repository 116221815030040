import { useMemo } from 'react';
import toast from 'react-hot-toast';
import diff from 'object-diff';

import { FormDialog } from '../../../components/dialog/FormDialog';
import { InputField } from '../../../components/input/InputField';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { getDisplayError } from '../../../utils/get-display-error';
import { LanguageSelectField } from '../../../components/LanguageSelect';
import { CategoryMultiSelectField } from '../../category/components/CategoryMultiSelect';
import { DOCUMENT_TYPE_OPTIONS } from '../../document/constants.client';
import { JurisdictionSelectField } from '../../document/components/JurisdictionSelect';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { BodyType as UpdateDocumentCollectionPayload } from '../endpoints/UpdateDocumentCollectionEndpoint';
import { useTeam } from '../../team/context/TeamContext';
import { ButtonProps } from '../../../components/button/Button';

export interface IDocumentCollectionMetadataDialogProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  showTrigger?: boolean;
  triggerSize?: ButtonProps['size'];
  triggerText?: React.ReactNode;
  triggerVariant?: ButtonProps['variant'];
  onComplete?: () => void;
  documentCollection: {
    id: string;
    name: string;
    documentType?: string | null;
    categories: Array<{ id: string; name: string }>;
    language?: string | null;
    jurisdiction?: string | null;
  };
}

export const DocumentCollectionMetadataDialog: React.FC<IDocumentCollectionMetadataDialogProps> = (props) => {
  const { open, setOpen, showTrigger, triggerSize, triggerText, triggerVariant, documentCollection, onComplete } =
    props;
  const { team } = useTeam();
  const initialValues = useMemo(() => {
    return {
      name: documentCollection.name,
      documentType: DOCUMENT_TYPE_OPTIONS.find((v) => v.key === documentCollection.documentType),
      categoryIds: (documentCollection.categories ?? []).map((v) => v.id),
      jurisdiction: documentCollection.jurisdiction,
      language: documentCollection.language,
    };
  }, [document]);

  const documentCollectionId = documentCollection.id;
  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      showTrigger={showTrigger}
      triggerSize={triggerSize}
      triggerText={triggerText}
      triggerVariant={triggerVariant}
      title="Collection Data"
      submitText="Update"
      onSubmit={async (newValues) => {
        const patch: Partial<typeof newValues> = diff(initialValues, newValues);
        if (Object.keys(patch).length === 0) {
          return;
        }

        try {
          const payload: UpdateDocumentCollectionPayload = {
            teamId: team.id,
            collectionId: documentCollectionId,
            data: {
              name: patch.name,
              language: patch.language ? patch.language : undefined,
              jurisdiction: patch.jurisdiction ? patch.jurisdiction : undefined,
              categoryIds: patch.categoryIds ?? undefined,
              documentType: patch.documentType?.key ?? undefined,
            },
          };
          await fetchEndpointData(`/api/v1/document-collection/update`, {
            method: 'POST',
            body: payload,
          });

          onComplete?.();

          toast.success('Document collection data has been updated');
        } catch (err) {
          toast.error('Could not update document collection data: ' + getDisplayError(err));
          throw err;
        }
      }}
      initialValues={initialValues}
    >
      <InputField type="text" labelText="Name" name="name" />
      <SimpleSelectField name="documentType" labelText="Document Type" items={DOCUMENT_TYPE_OPTIONS} />
      <CategoryMultiSelectField name="categoryIds" labelText="Document Categories" />
      <LanguageSelectField name="language" labelText="Language" />
      <JurisdictionSelectField name="jurisdiction" labelText="Jurisdiction" />
    </FormDialog>
  );
};
