import { useCallback, useState } from 'react';

export function useLocalStorage(key: string, initialValue: any) {
  const [value, _setValue] = useState(() => {
    try {
      const foundValue = window.localStorage.getItem(key);
      return foundValue ? JSON.parse(foundValue) : initialValue;
    } catch (err) {
      console.error(err);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (newValue: any) => {
      try {
        window.localStorage.setItem(key, JSON.stringify(newValue));
      } catch (err) {
        console.error(err);
      }

      _setValue(newValue);
    },
    [_setValue],
  );

  return [value, setValue];
}
