import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { ChevronDownIcon, ChevronUpIcon, DownloadIcon, TrashIcon } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import z from 'zod';
import React from 'react';

import { InputField } from '../../../components/input/InputField';
import { Button } from '../../../components/button/Button';
import { getDisplayError } from '../../../utils/get-display-error';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { QUESTIONS_PRESET_TYPE_OPTIONS } from '../constants.client';
import { nullthrows } from '../../../utils/invariant';
import classNames from '../../../utils/classnames';
import { TextAreaField } from '../../../components/textarea/TextAreaField';
import { CategoryMultiSelectField } from '../../category/components/CategoryMultiSelect';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { SpinnerBlock } from '../../../components/Spinner';
import type { ResponseType as QuestionsPresetResponseType } from '../endpoints/QuestionsPresetEndpoint';
import type { BodyType as DeleteQuestionsPresetsPayload } from '../endpoints/DeleteQuestionsPresetEndpoint';
import type {
  ResponseType as UpdateQuestionsPresetResponseType,
  BodyType as UpdateQuestionsPresetPayload,
} from '../endpoints/UpdateQuestionsPresetEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { useTeam } from '../../team/context/TeamContext';
import { useAuth } from '@/contexts/auth-context';

export const presetExportSchema = z.object({
  name: z.string(),
  type: z.string(),
  categories: z.array(z.string()),
  questions: z.array(
    z.object({
      name: z.string(),
      question: z.string(),
      additionalContext: z.string(),
      answerInstructions: z.string(),
    }),
  ),
});
export type PresetExportSchemaType = z.infer<typeof presetExportSchema>;

const updatePresetQuestionSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Required').required('Required'),
  question: Yup.string().min(1, 'Required').required('Required'),
  index: Yup.string(),
  additionalContext: Yup.string(),
  answerInstructions: Yup.string(),
});

const updatePresetSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Required').required('Required'),
  type: Yup.mixed().required('Required'),
  questions: Yup.array().of(updatePresetQuestionSchema).required('Required'),
});

interface ICreatePresetQuestionProps {
  index: number;
  onDelete: () => void;
  isLast?: boolean;
  onMove: (offset: number) => void;
}

const UpdatePresetQuestion = (props: ICreatePresetQuestionProps) => {
  const { index, onDelete, isLast, onMove } = props;

  return (
    <div
      className={classNames('w-full flex gap-4', {
        'border-b border-dark-08 mb-8': !isLast,
      })}
    >
      <div className="flex-1">
        <InputField labelText="Name" type="text" name={`questions.${index}.name`} />
        <InputField labelText="Question" type="text" name={`questions.${index}.question`} />
        <TextAreaField labelText="Additional context" name={`questions.${index}.additionalContext`} />
        <TextAreaField labelText="Additional answer instructions" name={`questions.${index}.answerInstructions`} />
      </div>

      <div className="pt-6 flex flex-col gap-2">
        <ConfirmDialog
          triggerText={<TrashIcon className="button-icon" />}
          aria-label="Delete question"
          title="Delete question"
          submitText="Delete"
          triggerVariant="destructive"
          description="Are you sure you want to delete this question?"
          onSubmit={onDelete}
        />
        <Button
          type="button"
          onTrigger={() => {
            onMove(-1);
          }}
          isDisabled={index === 0}
        >
          <ChevronUpIcon className="button-icon" />
        </Button>
        <Button
          type="button"
          onTrigger={() => {
            onMove(1);
          }}
          isDisabled={isLast}
        >
          <ChevronDownIcon className="button-icon" />
        </Button>
      </div>
    </div>
  );
};

interface UpdatePresetPageProps {
  preset: QuestionsPresetResponseType['preset'];
  mutate: () => void;
}

const UpdatePresetPageImpl: React.FC<UpdatePresetPageProps> = (props) => {
  const { preset, mutate } = props;
  const navigate = useNavigate();
  const { me } = useAuth();
  const { team } = useTeam();

  const formikbag = useFormik({
    initialValues: {
      name: preset.name,
      type: QUESTIONS_PRESET_TYPE_OPTIONS.find((v) => preset.type === v.key),
      categoryIds: preset.categories.map((v) => v.id),
      questions: preset.questions.map((q) => {
        return {
          index: q.index.toString(10),
          name: q.name,
          question: q.question,
          additionalContext: q.additionalContext,
          answerInstructions: q.answerInstructions,
        };
      }),
    },
    validationSchema: updatePresetSchema,
    onSubmit: async (values) => {
      try {
        const payload: UpdateQuestionsPresetPayload = {
          teamId: team.id,
          presetId: preset.id,
          data: {
            ...values,
            questions: values.questions.map((v, idx) => {
              return {
                ...v,
                index: idx,
                additionalContext: v.additionalContext,
                answerInstructions: v.answerInstructions,
              };
            }),
            categoryIds: values.categoryIds ?? [],
            type: values.type?.key!,
          },
        };
        await fetchEndpointData<UpdateQuestionsPresetResponseType>('/api/v1/questions-preset/update', {
          method: 'POST',
          body: payload,
        });
        mutate();
        toast.success('Preset updated');
      } catch (err: any) {
        toast.error('Could not update preset: ' + getDisplayError(err));
      }
    },
  });

  const { isSubmitting, values, handleSubmit, setValues } = formikbag;
  return (
    <div className="page-content">
      <PageHeader title="Create preset" />

      <div className="mb-4 flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: 'Presets',
              to: '..',
            },
            {
              name: preset.name,
            },
          ]}
        />

        <div className="flex gap-2">
          {me.isSuperUser && (
            <Button
              iconLeft={<DownloadIcon className="button-icon" />}
              onTrigger={() => {
                const nameKey = preset.name
                  .toLowerCase()
                  .replace(/\s+/, '_')
                  .replace(/[^a-z_]/g, '');
                const json = JSON.stringify({
                  name: preset.name,
                  type: preset.type,
                  categories: preset.categories.map((v) => v.name),
                  questions: preset.questions.map((q) => {
                    return {
                      name: q.name,
                      question: q.question,
                      additionalContext: q.additionalContext,
                      answerInstructions: q.answerInstructions,
                    };
                  }),
                });
                var blob = new Blob([json], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${nameKey}_preset_export.json`;
                link.click();
              }}
            >
              Export
            </Button>
          )}

          <ConfirmDialog
            triggerText="Delete"
            title="Delete preset"
            submitText="Delete"
            triggerVariant="destructive"
            description={`Are you sure you want to delete the preset ${preset.name}?`}
            onSubmit={async () => {
              try {
                const payload: DeleteQuestionsPresetsPayload = {
                  teamId: team.id,
                  presetIds: [preset.id],
                };
                await fetchEndpointData('/api/v1/questions-preset/delete', {
                  method: 'DELETE',
                  body: payload,
                });
                toast.success('Preset has been deleted');
                navigate('..');
              } catch (err) {
                toast.error('Could not delete preset: ' + getDisplayError(err));
              }
            }}
          />
        </div>
      </div>

      <div className="card">
        <FormikProvider value={formikbag}>
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <InputField labelText="Name" type="text" name="name" />

            <SimpleSelectField labelText="Type" name="type" items={[...QUESTIONS_PRESET_TYPE_OPTIONS]} />

            <CategoryMultiSelectField name="categoryIds" labelText="Document Categories" />

            <FieldArray
              name="questions"
              render={(arrayHelpers) => {
                return (
                  <div className="mb-4">
                    <div className="flex justify-between">
                      <div className="mt-4 font-medium mb-2">Questions</div>
                      <Button
                        type="button"
                        onTrigger={() => {
                          arrayHelpers.push({
                            name: '',
                            question: '',
                            additionalContext: '',
                            answerInstructions: '',
                          });
                        }}
                      >
                        Add Question
                      </Button>
                    </div>

                    {values.questions.length > 0
                      ? values.questions.map((_, index) => (
                          <UpdatePresetQuestion
                            key={index}
                            index={index}
                            onDelete={() => {
                              arrayHelpers.remove(index);
                            }}
                            onMove={(offset) => {
                              const newIndex = index + offset;
                              if (newIndex < 0 || newIndex >= values.questions.length) {
                                return;
                              }
                              arrayHelpers.move(index, newIndex);
                            }}
                            isLast={index === values.questions.length - 1}
                          />
                        ))
                      : 'No questions, create at least one question'}
                  </div>
                );
              }}
            />

            <Button type="submit" variant="primary" isDisabled={isSubmitting} isLoading={isSubmitting}>
              Update Preset
            </Button>
          </form>
        </FormikProvider>
      </div>
    </div>
  );
};

export const UpdatePresetPage = () => {
  const { presetId: _presetId } = useParams<{ presetId: string }>();
  const presetId = nullthrows(_presetId, 'Preset id not defined');
  const { data, error, isLoading, mutate } = useSWR<QuestionsPresetResponseType>(
    `/api/v1/questions-preset/get/${presetId}`,
    fetchEndpointData,
  );

  if (error) {
    throw error;
  }

  if (isLoading) {
    return <SpinnerBlock message="Loading preset..." />;
  }

  const preset = nullthrows(data?.preset, 'Preset not found');
  return <UpdatePresetPageImpl preset={preset} mutate={mutate} />;
};
