import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckIcon, ChevronDownIcon, ChevronRightIcon, FileIcon, FolderIcon, PlusIcon, TrashIcon } from 'lucide-react';

import { useExplorerTree } from '../../../explorerTree/contexts/ExplorerContext';
import { DialogRoot, DialogContent } from '../../../../components/dialog/Dialog';
import { EXPLORER_ROOT_ID, ExplorerTreeEntry } from '../../../explorerTree/explorer-tree';
import classNames from '../../../../utils/classnames';
import { Button } from '../../../../components/button/Button';
import { ExplorerEntryType } from '../../../explorerTree/enums';

interface ICollectionNodeProps {
  collection: ExplorerTreeEntry;
  openCollectionId: string | null;
  onOpen: (v: ExplorerTreeEntry, isOpened: boolean) => void;
  selectedNodeIds: string[];
  onSelect: (v: ExplorerTreeEntry) => void;
  isSelectable: boolean;
  expandedCollections: string[];
}

export const CollectionNode: React.FC<ICollectionNodeProps> = (props) => {
  const { collection, openCollectionId, onOpen, selectedNodeIds, onSelect, isSelectable, expandedCollections } = props;

  const isExpanded = expandedCollections.includes(collection.id);
  const isSelected = selectedNodeIds.includes(collection.id);
  const childcollections = collection.children.filter((node) => node.type === ExplorerEntryType.Collection);
  return (
    <div
      className={classNames('grid gap-1', {
        'mb-1': !isExpanded,
      })}
    >
      <div className="card-no-padding flex justify-between overflow-hidden">
        <div
          className={classNames('flex gap-1 items-center hover:bg-gray-600 w-full pl-1 cursor-pointer', {
            'hover:text-white': openCollectionId !== collection.id,
            'bg-gray-500 text-white': openCollectionId === collection.id,
          })}
          onClick={() => {
            onOpen(collection, openCollectionId === collection.id);
          }}
        >
          <div>{isExpanded ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronRightIcon className="w-4 h-4" />}</div>
          <div>{collection.name}</div>
        </div>
        {isSelectable && (
          <div
            className={classNames(
              'flex items-center px-1 border-l border-gray-200 text-gray-600 cursor-pointer hover:bg-gray-600',
              {
                'hover:text-white': !isSelected,
                'bg-gray-500 text-white': isSelected,
              },
            )}
            onClick={() => {
              onSelect(collection);
            }}
          >
            {isSelected ? <CheckIcon className="w-4 h-4" /> : <PlusIcon className="w-4 h-4" />}
          </div>
        )}
      </div>
      {isExpanded && (
        <div className="ml-2">
          {childcollections.map((v) => {
            return (
              <CollectionNode
                key={v.id}
                collection={v}
                openCollectionId={openCollectionId}
                onOpen={onOpen}
                onSelect={onSelect}
                selectedNodeIds={selectedNodeIds}
                isSelectable={isSelectable}
                expandedCollections={expandedCollections}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

interface IDocumentListProps {
  collection: ExplorerTreeEntry;
  selectedNodeIds: string[];
  onSelectNode: (v: ExplorerTreeEntry) => void;
}

export const DocumentList: React.FC<IDocumentListProps> = (props) => {
  const { collection, selectedNodeIds, onSelectNode } = props;
  const [hoveredDoc, setHoveredDoc] = useState<string | null>(null);

  const documents = collection.children.filter((node) => node.type === ExplorerEntryType.Document);

  if (!documents.length) {
    return <div>{`No documents found in ${collection.name}`}</div>;
  }

  return (
    <div className="grid gap-1">
      {documents.map((v) => {
        const isHovered = v.id === hoveredDoc;
        const isSelected = selectedNodeIds.includes(v.id);

        return (
          <div
            key={v.id}
            className={classNames('rounded-md flex justify-between overflow-hidden', {
              'bg-gray-600 text-white': isHovered,
              'bg-gray-500 text-white': isSelected && !isHovered,
              'bg-gray-100': !isHovered && !isSelected,
            })}
            onMouseEnter={() => {
              setHoveredDoc(v.id);
            }}
            onMouseLeave={() => {
              setHoveredDoc((prev) => {
                if (prev === v.id) {
                  return null;
                }
                return prev;
              });
            }}
            onClick={() => {
              onSelectNode(v);
            }}
          >
            <div className="w-full pl-1 cursor-pointer">{v.name}</div>
            <div
              className={classNames('flex items-center px-1 border-l border-gray-200 cursor-pointer', {
                'text-gray-600': !isHovered && !isSelected,
                'text-white': isHovered || isSelected,
              })}
            >
              {isSelected ? <CheckIcon className="w-4 h-4" /> : <PlusIcon className="w-4 h-4" />}
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface ISelectionListProps {
  selectedNodeIds: string[];
  onDeselectNode: (v: ExplorerTreeEntry) => void;
}

export const SelectionList: React.FC<ISelectionListProps> = (props) => {
  const { selectedNodeIds, onDeselectNode } = props;
  const { tree, treeKey } = useExplorerTree();

  const selectedNodes: ExplorerTreeEntry[] = useMemo(() => {
    return selectedNodeIds
      .map((v) => {
        return tree.entries.get(v);
      })
      .filter(Boolean) as ExplorerTreeEntry[];
  }, [selectedNodeIds, treeKey]);

  if (!selectedNodes.length) {
    return <div>No selection</div>;
  }

  return (
    <div className="grid gap-1">
      {selectedNodes.map((v) => {
        return (
          <div key={v.id} className="bg-gray-100 rounded-md flex justify-between overflow-hidden">
            <div className="w-full pl-1 flex items-center gap-1">
              {v.type === ExplorerEntryType.Collection ? (
                <FolderIcon className="folder-icon-style w-4 h-4" />
              ) : (
                <FileIcon className="file-icon-style w-4 h-4" />
              )}
              <div>{v.name}</div>
            </div>
            <div
              className="flex items-center px-1 border-l border-gray-200 cursor-pointer hover:bg-red-400 hover:text-white"
              onClick={() => {
                onDeselectNode(v);
              }}
            >
              <TrashIcon className="w-4 h-4" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export interface IDocumentPickerProps {
  showRoot?: boolean;
  title?: string;
  onlyCollections?: boolean;
  onlyDocuments?: boolean;
  multiSelect?: boolean;
  initialSelection?: string[];
  onSubmit: (selectedNodes: ExplorerTreeEntry[]) => void;
}

export const DocumentPicker: React.FC<IDocumentPickerProps> = (props) => {
  const { title, initialSelection = [], onSubmit, onlyCollections, onlyDocuments, multiSelect, showRoot } = props;
  const { tree } = useExplorerTree();
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  const [openedCollection, setOpenedCollection] = useState<null | ExplorerTreeEntry>(null);
  const [expandedCollections, setExpandedCollections] = useState<string[]>([EXPLORER_ROOT_ID]);

  useEffect(() => {
    setSelectedNodeIds(initialSelection);
  }, [initialSelection.sort().join(',')]);

  const handleSelect = useCallback(
    (v: ExplorerTreeEntry) => {
      if (!multiSelect) {
        setSelectedNodeIds((prev) => {
          const setValue = new Set(prev);
          if (setValue.has(v.id)) {
            return [];
          } else {
            return [v.id];
          }
        });
      } else {
        setSelectedNodeIds((prev) => {
          const setValue = new Set(prev);
          if (setValue.has(v.id)) {
            setValue.delete(v.id);
          } else {
            setValue.add(v.id);
          }
          return [...setValue];
        });
      }
    },
    [setSelectedNodeIds, multiSelect],
  );

  const handleCollectionOpen = useCallback(
    (v: ExplorerTreeEntry, isCurrentActive: boolean) => {
      if (v.id !== EXPLORER_ROOT_ID) {
        setExpandedCollections((prev) => {
          const setValue = new Set(prev);
          if (setValue.has(v.id)) {
            if (!onlyCollections && !isCurrentActive) {
              return prev;
            }

            setValue.delete(v.id);
          } else {
            setValue.add(v.id);
          }
          return [...setValue];
        });
      }

      if (onlyCollections) {
        return;
      }

      setOpenedCollection(v);
    },
    [setOpenedCollection, setExpandedCollections, onlyCollections],
  );

  const handleSelectCollection = useCallback(
    (v: ExplorerTreeEntry) => {
      if (onlyDocuments) {
        return;
      }

      handleSelect(v);
    },
    [handleSelect, onlyCollections],
  );

  let gridTemplateColumns = '';
  if (multiSelect) {
    if (onlyCollections) {
      gridTemplateColumns = '1fr 1fr';
    } else {
      gridTemplateColumns = '1fr 2fr 1fr';
    }
  } else {
    if (onlyCollections) {
      gridTemplateColumns = '1fr';
    } else {
      gridTemplateColumns = '1fr 2fr';
    }
  }

  const selectedNode = selectedNodeIds.length ? tree.entries.get(selectedNodeIds[0]!) : null;
  const rootCollections = tree.root.children.filter((node) => node.type === ExplorerEntryType.Collection);
  return (
    <div className="flex flex-col gap-4" style={{ height: '80vh' }}>
      <div className="heading-two">{title ?? 'Select documents / folders'}</div>
      <div className="grid gap-4" style={{ gridTemplateColumns }}>
        <div>
          <div className="font-medium mb-1">Collections</div>
          <div className="overflow-y-auto" style={{ height: 'calc(80vh - 8rem)' }}>
            {showRoot ? (
              <CollectionNode
                key={tree.root.id}
                collection={tree.root}
                openCollectionId={openedCollection?.id || null}
                onOpen={handleCollectionOpen}
                onSelect={handleSelectCollection}
                selectedNodeIds={selectedNodeIds}
                isSelectable={!onlyDocuments}
                expandedCollections={expandedCollections}
              />
            ) : (
              rootCollections.map((collection) => {
                return (
                  <CollectionNode
                    key={collection.id}
                    collection={collection}
                    openCollectionId={openedCollection?.id || null}
                    onOpen={handleCollectionOpen}
                    onSelect={handleSelectCollection}
                    selectedNodeIds={selectedNodeIds}
                    isSelectable={!onlyDocuments}
                    expandedCollections={expandedCollections}
                  />
                );
              })
            )}
          </div>
        </div>
        {!onlyCollections && (
          <div>
            <div className="font-medium mb-1">Documents</div>
            <div className="overflow-y-auto" style={{ height: 'calc(80vh - 8rem)' }}>
              {!openedCollection && <div>Select a collection to view documents</div>}
              {openedCollection && (
                <DocumentList
                  collection={openedCollection}
                  selectedNodeIds={selectedNodeIds}
                  onSelectNode={handleSelect}
                />
              )}
            </div>
          </div>
        )}
        {multiSelect && (
          <div>
            <div className="font-medium mb-1">Selection</div>
            <div className="overflow-y-auto" style={{ height: 'calc(80vh - 8rem)' }}>
              {<SelectionList selectedNodeIds={selectedNodeIds} onDeselectNode={handleSelect} />}
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between">
        <div>{!multiSelect && selectedNode && `Selected: ${selectedNode.name}`}</div>

        <div className="flex gap-2">
          <Button
            onTrigger={() => {
              setSelectedNodeIds([]);
            }}
          >
            Clear selection
          </Button>
          <Button
            variant="primary"
            onTrigger={() => {
              const selectedNodes = selectedNodeIds
                .map((v) => {
                  return tree.entries.get(v);
                })
                .filter(Boolean) as ExplorerTreeEntry[];

              onSubmit(selectedNodes);
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export interface IDocumentPickerDialogProps extends IDocumentPickerProps {
  open: boolean;
  onOpenChange: (newOpen: boolean) => void;
}

export const DocumentPickerDialog: React.FC<IDocumentPickerDialogProps> = (props) => {
  const { open, onOpenChange, ...otherProps } = props;

  return (
    <DialogRoot open={open} onOpenChange={onOpenChange}>
      <DialogContent className="document-selection-dialog">
        <DocumentPicker {...otherProps} />
      </DialogContent>
    </DialogRoot>
  );
};

export interface IDocumentPickerDialogWithTriggerProps extends IDocumentPickerDialogProps {
  triggerText: React.ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  open: boolean;
  onOpenChange: (newOpen: boolean) => void;
}

export const DocumentPickerDialogWithTrigger: React.FC<IDocumentPickerDialogWithTriggerProps> = (props) => {
  const { triggerText, isLoading, isDisabled, ...otherProps } = props;

  return (
    <div>
      <Button
        onTrigger={() => {
          otherProps.onOpenChange(true);
        }}
        isLoading={isLoading}
        isDisabled={isDisabled}
      >
        {triggerText}
      </Button>

      <DocumentPickerDialog {...otherProps} />
    </div>
  );
};
