import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { ITableHeader, Table } from '../../../components/table/Table';
import { useTeam } from '@/app/team/context/TeamContext';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { LinkButton } from '../../../components/button/Button';
import { formatDateTime } from '../../../utils/date';
import { SpinnerBlock } from '../../../components/Spinner';
import { Pagination } from '../../../components/Pagination';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as QuestionsPresetsResponseType } from '../endpoints/QuestionsPresetsEndpoint';
import { ImportButton } from '@/components/ImportButton';
import { presetExportSchema, PresetExportSchemaType } from './UpdatePresetPage';
import { getDisplayError } from '@/utils/get-display-error';
import type {
  BodyType as CreateQuestionsPresetPayload,
  ResponseType as CreateQuestionsPresetResponseType,
} from '../endpoints/CreateQuestionsPresetEndpoint';
import { useAuth } from '@/contexts/auth-context';

const PRESETS_TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'id',
    name: 'Name',
  },
  {
    id: 'type',
    name: 'Type',
  },
  {
    id: 'createdAt',
    name: 'Created at',
  },
];

export const PresetsPage = () => {
  const { team } = useTeam();
  const { me } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [refreshKey, setRefreshKey] = useState(0);
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const searchParams = new URLSearchParams();
      searchParams.set('teamId', team.id);
      if (variables.cursor) {
        searchParams.set('id', variables.cursor);
      }
      searchParams.set('take', variables.take.toString());
      const result = await fetchEndpointData<QuestionsPresetsResponseType>(
        `/api/v1/questions-preset/list?${searchParams.toString()}`,
      );
      return result.presets ?? [];
    },
    [team.id],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 40,
    cursor: searchParams.get('pageCursor'),
    fetcher: pageFetcher,
    refreshToken: refreshKey,
  });

  const pageCursor = page.variables.cursor;
  useEffect(() => {
    if (!pageCursor) return;

    setSearchParams((prev) => {
      prev.set('pageCursor', pageCursor);
      return prev;
    });
  }, [pageCursor]);

  const history = page.data ?? [];
  const title = 'Presets';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: title,
            },
          ]}
        />

        <div className="flex gap-2">
          {me.isSuperUser && (
            <ImportButton
              accept="application/json"
              onFileUpload={async (files) => {
                try {
                  if (files.length > 0) {
                    const firstFile = files[0]!;
                    const text = await firstFile.text();
                    const json = presetExportSchema.parse(JSON.parse(text)) as PresetExportSchemaType;

                    const payload: CreateQuestionsPresetPayload = {
                      teamId: team.id,
                      data: {
                        name: json.name,
                        type: json.type,
                        categoryIds: [],
                        questions: json.questions.map((v, idx) => {
                          return {
                            ...v,
                            index: idx,
                            additionalContext: v.additionalContext,
                            answerInstructions: v.answerInstructions,
                          };
                        }),
                      },
                    };
                    await fetchEndpointData<CreateQuestionsPresetResponseType>('/api/v1/questions-preset/create', {
                      method: 'POST',
                      body: payload,
                    });

                    setRefreshKey((prev) => prev + 1);

                    toast.success('Preset imported succesfully');
                  }
                } catch (err) {
                  toast.error(`Could not import preset: ${getDisplayError(err)}`);
                }
              }}
            >
              Import Preset
            </ImportButton>
          )}
          <LinkButton to="new">Create preset</LinkButton>
        </div>
      </div>

      {!!history.length && (
        <Table
          idKey="id"
          headers={PRESETS_TABLE_HEADERS}
          data={history}
          mapData={(entry) => {
            return [
              <Link to={entry.id} className="w-full hover:text-blue-200">
                {entry.name}
              </Link>,
              entry.type,
              formatDateTime(entry.createdAt),
            ];
          }}
        />
      )}

      {!history.length && !page.isFetching && (
        <div className="card">
          <div>No presets found.</div>
        </div>
      )}

      {!history.length && page.isFetching && (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </div>
  );
};
