import { TrashIcon } from 'lucide-react';
import { createId } from '@paralleldrive/cuid2';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { LinkButton } from '../../../components/button/Button';
import { useTeam } from '@/app/team/context/TeamContext';
import { ITableHeader, Table } from '../../../components/table/Table';
import { formatDate } from '../../../utils/date';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { SimpleSelect } from '../../../components/select/SimpleSelect';
import { ROLE_OPTIONS } from '../constants.client';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { Pagination } from '../../../components/Pagination';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as UsersResponseType } from '../endpoints/UsersEndpoint';
import type { BodyType as DeleteUserPayload } from '../endpoints/DeleteUserEndpoint';
import type { BodyType as UpdateUserRolePayload } from '../endpoints/UpdateUserRoleEndpoint';

const USERS_HEADER: ITableHeader[] = [
  {
    id: 'name',
    name: 'Name',
  },
  {
    id: 'email',
    name: 'E-mail',
  },
  {
    id: 'role',
    name: 'Role',
  },
  {
    id: 'joinedAt',
    name: 'Joined at',
  },
  {
    id: 'delete',
    name: 'Delete',
  },
];

export const UsersPage = () => {
  const { team } = useTeam();
  const [refreshToken, setRefreshToken] = useState(() => createId());

  const refreshUsers = useCallback(() => {
    setRefreshToken(createId());
  }, [setRefreshToken]);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const searchParams = new URLSearchParams();
      searchParams.set('teamId', team.id);
      if (variables.cursor) {
        searchParams.set('id', variables.cursor);
      }
      searchParams.set('take', variables.take.toString());
      const result = await fetchEndpointData<UsersResponseType>(`/api/v1/user/list?${searchParams.toString()}`);
      return result.users ?? [];
    },
    [team.id],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 40,
    refreshToken,
    cursor: searchParams.get('pageCursor'),
    fetcher: pageFetcher,
  });

  const pageCursor = page.variables.cursor;
  useEffect(() => {
    if (!pageCursor) return;

    setSearchParams((prev) => {
      prev.set('pageCursor', pageCursor);
      return prev;
    });
  }, [pageCursor]);

  const users = page.data ?? [];
  return (
    <div className="page-content">
      <PageHeader title="Users" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Settings',
              to: '..',
            },
            {
              name: 'Users',
            },
          ]}
        />

        <div className="flex gap-2">
          <LinkButton to="add">Add User</LinkButton>
        </div>
      </div>

      <div className="card">
        <Table
          idKey="id"
          headers={USERS_HEADER}
          data={users}
          mapData={(user) => {
            return [
              user.user.name,
              user.user.email,
              <SimpleSelect
                items={ROLE_OPTIONS}
                selectedItem={ROLE_OPTIONS.find((v) => v.key === '' + user.role) ?? ROLE_OPTIONS[0]!}
                onSelect={async (selectedItem) => {
                  if (selectedItem) {
                    try {
                      const newRole = +selectedItem.key;
                      const payload: UpdateUserRolePayload = {
                        teamId: team.id,
                        userId: user.user.id,
                        newRole,
                      };
                      await fetchEndpointData(`/api/v1/user/update-role`, {
                        method: 'POST',
                        body: payload,
                      });
                      refreshUsers();
                      user.role = newRole;
                      toast.success('Role has been updated');
                    } catch (err) {
                      toast.error(`Could not update user: ${getDisplayError(err)}`);
                    }
                  }
                }}
              />,
              formatDate(user.joinedAt),
              <ConfirmDialog
                triggerText={<TrashIcon className="button-icon" />}
                title="Delete User"
                submitText="Delete User"
                description={<div>{`Are you sure you want to remove ${user.user.name} from the team?`}</div>}
                onSubmit={async () => {
                  try {
                    const payload: DeleteUserPayload = {
                      teamId: team.id,
                      userId: user.user.id,
                    };
                    await fetchEndpointData(`/api/v1/user/delete`, {
                      method: 'DELETE',
                      body: payload,
                    });
                    refreshUsers();
                    toast.success('User has been removed');
                  } catch (err) {
                    toast.error(`Could not remove user: ${getDisplayError(err)}`);
                  }
                }}
              />,
            ];
          }}
        />
      </div>

      <div className="py-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </div>
  );
};
