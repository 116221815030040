import { useMemo } from 'react';
import toast from 'react-hot-toast';
import diff from 'object-diff';

import { FormDialog } from '../../../components/dialog/FormDialog';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { DOCUMENT_TYPE_OPTIONS } from '../constants.client';
import { getDisplayError } from '../../../utils/get-display-error';
import { LanguageSelectField } from '../../../components/LanguageSelect';
import { JurisdictionSelectField } from './JurisdictionSelect';
import { CategoryMultiSelectField } from '../../category/components/CategoryMultiSelect';
import { BodyType as UpdateDocumentPayload } from '../endpoints/UpdateDocumentEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { JurisdictionEnum, LanguageEnum } from '../prompts/constants';

export interface IBulkDocumentMetadataDialogProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  onComplete?: () => void;
  showTrigger?: boolean;
  triggerText?: React.ReactNode;
  selectedDocumentIds: string[];
}

export const BulkDocumentMetadataDialog: React.FC<IBulkDocumentMetadataDialogProps> = (props) => {
  const { open, setOpen, showTrigger, triggerText, selectedDocumentIds, onComplete } = props;

  const initialDocumentData: {
    documentType: (typeof DOCUMENT_TYPE_OPTIONS)[number] | null;
    categoryIds: string[];
    jurisdiction: JurisdictionEnum | null;
    language: LanguageEnum | null;
  } = useMemo(() => {
    return {
      documentType: null,
      categoryIds: [],
      jurisdiction: null,
      language: null,
    };
  }, [document]);

  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      showTrigger={showTrigger}
      triggerText={triggerText}
      title="Document Data"
      submitText="Update"
      isDisabled={!selectedDocumentIds.length}
      onSubmit={async (newValues) => {
        const patch: Partial<typeof newValues> = diff(initialDocumentData, newValues);
        if (Object.keys(patch).length === 0) {
          return;
        }

        let successCount = 0;
        let errorCount = 0;
        let lastError = null;
        for (const documentId of selectedDocumentIds) {
          try {
            const payload: UpdateDocumentPayload = {
              documentId,
              data: {
                language: patch.language ? patch.language : undefined,
                jurisdiction: patch.jurisdiction ? patch.jurisdiction : undefined,
                categoryIds: patch.categoryIds ?? undefined,
                documentType: patch.documentType?.key ?? undefined,
              },
            };
            await fetchEndpointData('/api/v1/document/update', {
              method: 'POST',
              body: payload,
            });
            successCount += 1;
          } catch (err) {
            errorCount += 1;
            toast.error('Could not update document data: ' + getDisplayError(err));
            lastError = err;
          }
        }

        if (successCount > 0) {
          toast.success(`Document data has been updated for ${successCount} documents`);
        }

        if (errorCount > 0) {
          toast.error(`Document data could not be updated for ${errorCount} documents`);
        }

        if (lastError) {
          throw lastError;
        }

        onComplete?.();
      }}
      initialValues={initialDocumentData}
    >
      <SimpleSelectField name="documentType" labelText="Document Type" items={DOCUMENT_TYPE_OPTIONS} />
      <CategoryMultiSelectField name="categoryIds" labelText="Document Categories" />
      <LanguageSelectField name="language" labelText="Language" />
      <JurisdictionSelectField name="jurisdiction" labelText="Jurisdiction" />
    </FormDialog>
  );
};
