import {
  SettingsIcon,
  FileIcon,
  BriefcaseIcon,
  MessageCircleIcon,
  ArchiveIcon,
  ChevronRight,
  ChevronLeft,
  HomeIcon,
  RefreshCw,
  SlidersIcon,
  TagIcon,
  CoinsIcon,
  LineChartIcon,
} from 'lucide-react';
import { useEffect, useState } from 'react';

import { useAuth } from '../../contexts/auth-context';
import { useNavigate } from 'react-router-dom';
import { Button, LinkButton } from '../button/Button';
import { AuthRoles } from '../../app/user/auth-roles';
import { UserMenu } from '../UserMenu';
import { TeamAddons } from '../../app/team/enums';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTeam } from '@/app/team/context/TeamContext';
import { BaseButton } from '../button/BaseButton';
import { DialogContent, DialogRoot, DialogTrigger } from '../dialog/Dialog';
import { useExplorerTree } from '../../app/explorerTree/contexts/ExplorerContext';

const TeamSelectorDialog = () => {
  const { myTeams } = useAuth();
  const navigate = useNavigate();
  const { team: selectedTeam } = useTeam();
  const [open, setOpen] = useState(false);

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <BaseButton variant="ghost" onClick={() => setOpen(true)}>
          {selectedTeam.name}
        </BaseButton>
      </DialogTrigger>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">Select a team to switch</h1>

        <div className="grid gap-2 overflow-y-auto" style={{ maxHeight: '50vh' }}>
          {myTeams.map((t) => {
            return (
              <button
                key={`${t.team.id}`}
                className="bg-gray-200 hover:bg-gray-300 rounded p-1 cursor-pointer text-left"
                onClick={() => {
                  navigate(`/app/t/${t.team.id}`);
                  setOpen(false);
                }}
              >
                {t.team.name}
              </button>
            );
          })}
        </div>
      </DialogContent>
    </DialogRoot>
  );
};

export interface IDashboardProps {
  children: React.ReactNode;
}

export const TeamDashboard: React.FC<IDashboardProps> = (props) => {
  const { children } = props;
  const { me, myTeams } = useAuth();
  const { team: selectedTeam } = useTeam();
  const [_isMinimalSidebar, setIsMinimalSidebar] = useLocalStorage('jurimesh_dashboard_min_sidebar', false);
  const [pendingDocuments, setPendingDocuments] = useState(0);
  const { tree: explorerTree } = useExplorerTree();

  useEffect(() => {
    const disposable = explorerTree.onTreeChange(() => {
      setPendingDocuments(explorerTree.getPendingDocuments().length);
    });
    setPendingDocuments(explorerTree.getPendingDocuments().length);

    return () => {
      disposable.dispose();
    };
  }, [explorerTree]);

  const role = myTeams.find((t) => t.team.id === selectedTeam?.id)?.role ?? 0;
  const isMinimalSidebar = selectedTeam && _isMinimalSidebar;
  return (
    <div className="grid" style={{ gridTemplateColumns: isMinimalSidebar ? '4rem 1fr' : '12rem 1fr' }}>
      {isMinimalSidebar ? (
        <div className="border-r border-r-gray-200 min-h-screen p-2 bg-gray-100">
          <Button
            variant="ghost"
            onTrigger={() => {
              setIsMinimalSidebar(false);
            }}
          >
            <ChevronRight className="button-icon" />
          </Button>

          <div className="grid my-4">
            <LinkButton variant="ghost" to={`/app/t/${selectedTeam.id}`} title="Home">
              <HomeIcon className="button-icon" />
            </LinkButton>
            <div className="w-full">
              <LinkButton variant="ghost" to={`/app/t/${selectedTeam.id}/chat/new`} title="Chat">
                <MessageCircleIcon className="button-icon" />
              </LinkButton>
            </div>
            <div className="w-full">
              <LinkButton variant="ghost" to={`/app/t/${selectedTeam.id}/documents`} title="Documents">
                <FileIcon className="button-icon" />
              </LinkButton>
            </div>
            {selectedTeam.enabledAddons.includes(TeamAddons.DueDilligence) && (
              <>
                <LinkButton variant="ghost" to={`/app/t/${selectedTeam.id}/workspace`} title="Workspaces">
                  <ArchiveIcon className="button-icon" />
                </LinkButton>
                <LinkButton variant="ghost" to={`/app/t/${selectedTeam.id}/questions-preset`} title="Presets">
                  <SlidersIcon className="button-icon" />
                </LinkButton>
              </>
            )}
            <div className="w-full">
              <LinkButton variant="ghost" to={`/app/t/${selectedTeam.id}/categories`} title="Categories">
                <TagIcon className="button-icon" />
              </LinkButton>
            </div>
            {pendingDocuments > 0 && (
              <div className="w-full">
                <LinkButton
                  variant="ghost"
                  to={`/app/t/${selectedTeam.id}/processing-queue`}
                  title={`${pendingDocuments} documents processing`}
                >
                  <RefreshCw className="button-icon" />
                </LinkButton>
              </div>
            )}
            <div className="w-full">
              <LinkButton variant="ghost" to={`/app/t/${selectedTeam.id}/insights`} title="Insights">
                <LineChartIcon className="button-icon" />
              </LinkButton>
            </div>
            <div className="w-full">
              <LinkButton variant="ghost" to={`/app/t/${selectedTeam.id}/usage`} title="Usage">
                <CoinsIcon className="button-icon" />
              </LinkButton>
            </div>
            {role >= AuthRoles.Admin && (
              <div className="w-full">
                <LinkButton variant="ghost" to={`/app/t/${selectedTeam.id}/settings`} title="Settings">
                  <SettingsIcon className="button-icon" />
                </LinkButton>
              </div>
            )}

            {me.isSuperUser && (
              <div className="w-full">
                <LinkButton variant="ghost" to="/app/internal" title="Backoffice">
                  <BriefcaseIcon className="button-icon" />
                </LinkButton>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="min-h-screen p-2 border-r bg-gray-100 border-r-gray-200">
          <div className="w-full">
            <Button
              variant="ghost"
              iconLeft={<ChevronLeft className="button-icon" />}
              onTrigger={() => {
                setIsMinimalSidebar(true);
              }}
            >
              Minimize
            </Button>
          </div>

          <div className="grid my-4">
            <div className="w-full">
              <LinkButton
                variant="ghost"
                iconLeft={<HomeIcon className="button-icon" />}
                to={`/app/t/${selectedTeam.id}`}
                title="Home"
              >
                Home
              </LinkButton>
            </div>
            <div className="w-full">
              <LinkButton
                variant="ghost"
                iconLeft={<MessageCircleIcon className="button-icon" />}
                to={`/app/t/${selectedTeam.id}/chat/new`}
                title="Chat"
              >
                Chat
              </LinkButton>
            </div>
            <div className="w-full">
              <LinkButton
                variant="ghost"
                iconLeft={<FileIcon className="button-icon" />}
                to={`/app/t/${selectedTeam.id}/documents`}
                title="Documents"
              >
                Documents
              </LinkButton>
            </div>
            {selectedTeam.enabledAddons.includes(TeamAddons.DueDilligence) && (
              <>
                <LinkButton
                  variant="ghost"
                  iconLeft={<ArchiveIcon className="button-icon" />}
                  to={`/app/t/${selectedTeam.id}/workspace`}
                  title="Workspaces"
                >
                  Workspaces
                </LinkButton>
                <LinkButton
                  variant="ghost"
                  iconLeft={<SlidersIcon className="button-icon" />}
                  to={`/app/t/${selectedTeam.id}/questions-preset`}
                  title="Presets"
                >
                  Presets
                </LinkButton>
              </>
            )}
            <div className="w-full">
              <LinkButton
                variant="ghost"
                iconLeft={<TagIcon className="button-icon" />}
                to={`/app/t/${selectedTeam.id}/categories`}
                title="Categories"
              >
                Categories
              </LinkButton>
            </div>
            {pendingDocuments > 0 && (
              <div className="w-full">
                <LinkButton
                  variant="ghost"
                  iconLeft={<RefreshCw className="button-icon" />}
                  to={`/app/t/${selectedTeam.id}/processing-queue`}
                  title={`${pendingDocuments} documents processing`}
                >
                  {`${pendingDocuments} pending`}
                </LinkButton>
              </div>
            )}
            <div className="w-full">
              <LinkButton
                variant="ghost"
                iconLeft={<LineChartIcon className="button-icon" />}
                to={`/app/t/${selectedTeam.id}/insights`}
                title="Insights"
              >
                Insights
              </LinkButton>
            </div>
            <div className="w-full">
              <LinkButton
                variant="ghost"
                iconLeft={<CoinsIcon className="button-icon" />}
                to={`/app/t/${selectedTeam.id}/usage`}
                title="Usage"
              >
                Usage
              </LinkButton>
            </div>
            {role >= AuthRoles.Admin && (
              <div className="w-full">
                <LinkButton
                  variant="ghost"
                  iconLeft={<SettingsIcon className="button-icon" />}
                  to={`/app/t/${selectedTeam.id}/settings`}
                  title="Settings"
                >
                  Settings
                </LinkButton>
              </div>
            )}

            {me.isSuperUser && (
              <div className="w-full">
                <LinkButton
                  variant="ghost"
                  iconLeft={<BriefcaseIcon className="button-icon" />}
                  to="/app/internal"
                  title="Backoffice"
                >
                  Backoffice
                </LinkButton>
              </div>
            )}
          </div>
        </div>
      )}

      <div>
        <header className="flex justify-between p-2 border-b border-b-gray-300">
          <div className="flex items-center gap-2">
            {myTeams.length > 1 ? <TeamSelectorDialog /> : <div className="ml-2">{selectedTeam.name}</div>}
          </div>

          <UserMenu />
        </header>

        <main>{children}</main>
      </div>
    </div>
  );
};
