import toast from 'react-hot-toast';
import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import useSWR from 'swr';

import { FormDialog } from '../../../../../components/dialog/FormDialog';
import { useWorkspace } from '../WorkspaceContext';
import { useTeam } from '@/app/team/context/TeamContext';
import { SimpleSelectField } from '../../../../../components/select/SimpleSelectField';
import { getDisplayError } from '../../../../../utils/get-display-error';
import { ISimpleSelectItem } from '../../../../../components/select/SimpleSelect';
import { nullthrows } from '../../../../../utils/invariant';
import { workspaceCompaniesSort } from '../WorkspaceCompaniesPage';
import { QuestionsPresetType } from '../../../../questionsPreset/enums';
import { fetchEndpointData } from '../../../../../utils/fetch.client';
import type { ResponseType as QuestionsPresetsResponseType } from '../../../../questionsPreset/endpoints/QuestionsPresetsEndpoint';
import type { BodyType as StartPresetRunPayload } from '../../../endpoints/StartPresetRunEndpoint';
import { InputField } from '../../../../../components/input/InputField';

interface IValues {
  preset?: (ISimpleSelectItem & { type: string }) | null;
  companyName?: string | null;
}

const ConditionalFields = () => {
  const { companies } = useWorkspace();
  const { values } = useFormikContext<IValues>();

  const companyItems = useMemo(() => {
    return companies.sort(workspaceCompaniesSort).map((v) => {
      return {
        key: v.id,
        name: v.name,
      };
    });
  }, [companies]);

  const preset = values.preset;
  if (!preset || preset.type !== QuestionsPresetType.Company) {
    return null;
  }

  return <InputField name="companyName" labelText="Company Name" />;
};

export interface IStartPresetRunDialogProps {
  isProcessing?: boolean;
  onStart?: () => void;
}

export const StartPresetRunDialog: React.FC<IStartPresetRunDialogProps> = (props) => {
  const { isProcessing, onStart } = props;
  const { team } = useTeam();
  const { workspace } = useWorkspace();
  const { data, isLoading } = useSWR<QuestionsPresetsResponseType>(
    `/api/v1/questions-preset/list?teamId=${team.id}&take=100`,
    fetchEndpointData,
  );

  const presets = data?.presets || [];
  const items = presets.map((p) => {
    return {
      key: p.id,
      name: p.name,
      type: p.type,
    };
  });

  return (
    <FormDialog
      triggerText="Start Preset Run"
      triggerVariant="primary"
      title="Start Preset Run"
      submitText="Start"
      isDisabled={isProcessing}
      isLoading={isLoading && !presets.length}
      onSubmit={async (values) => {
        try {
          const presetId = nullthrows(values.preset?.key as string, 'No preset selected');
          const companyName = values.companyName?.trim() || null;
          const collectionId = workspace.documentCollection.id;

          if (values.preset?.type === QuestionsPresetType.Company && !companyName) {
            throw new Error('Select a company to run a company preset run');
          }

          const payload: StartPresetRunPayload = {
            presetId,
            companyName,
            workspaceId: workspace.id,
            collectionId,
          };
          await fetchEndpointData(`/api/v1/workspace/start-preset-run`, {
            method: 'POST',
            body: payload,
          });
          onStart?.();
          toast.success('Preset run has been started');
        } catch (err) {
          toast.error('Could not start preset run: ' + getDisplayError(err));
          throw err;
        }
      }}
      initialValues={
        {
          preset: null,
          companyName: null,
        } as IValues
      }
    >
      <SimpleSelectField name="preset" labelText="Preset" items={items} />

      <ConditionalFields />
    </FormDialog>
  );
};
