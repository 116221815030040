import { Emitter } from '../../utils/emitter';
import { Throttle } from '../../utils/Throttle';
import { getTeamStates } from '../team/context/TeamStates';

import type { ResponseType as DeletedExplorerEntriesResultType } from './endpoints/DeletedExplorerEntriesEndpoint';
import type { ResponseType as SyncExplorerEntriesResponseType } from './endpoints/SyncExplorerEntriesEndpoint';
import type { ResponseType as SyncCollectionsResponseType } from './endpoints/SyncCollectionsEndpoint';
import type { ResponseType as SyncDocumentsResponseType } from './endpoints/SyncDocumentsEndpoint';
import type { ResponseType as ExplorerSyncKeyResponseType } from './endpoints/ExplorerSyncKeyEndpoint';
import { fetchEndpointData } from '../../utils/fetch.client';
import { getJurisdiction, getLanguage, JurisdictionEnum, LanguageEnum } from '../document/prompts/constants';
import {
  DocumentIndexingStatus,
  DocumentTypeEnum,
  getDocumentIndexingStatus,
  getDocumentType,
} from '../document/enums';
import { ExplorerEntryType, getExplorerEntryType } from './enums';

export const EXPLORER_ROOT_ID = 'ROOT';

const PAGE_SIZE = 500;

type RawEntry = SyncExplorerEntriesResponseType['entries'][0];
type RawDocument = SyncDocumentsResponseType['documents'][0];
type RawCollection = SyncCollectionsResponseType['collections'][0];
type DeletedEntry = DeletedExplorerEntriesResultType['deletedEntries'][0];

export interface ICategory {
  id: string;
  name: string;
}

export interface IExplorerEntryCollectionData {
  id: string;
  isSynced: boolean;
  language?: LanguageEnum | null;
  jurisdiction?: JurisdictionEnum | null;
  documentType?: DocumentTypeEnum | null;
  createdAt: Date;
  updatedAt: Date;
  categories: Array<ICategory>;
}

export interface IExplorerEntryDocumentData {
  id: string;
  indexingStatus: DocumentIndexingStatus;
  hasExtractedMetadata: boolean;
  hasExtractedParties: boolean;
  date?: Date | null;
  language?: LanguageEnum | null;
  jurisdiction?: JurisdictionEnum | null;
  documentType: DocumentTypeEnum;
  ocrConfidence?: number | null;
  createdAt: Date;
  updatedAt: Date;
  categories: Array<ICategory>;
}

export class ExplorerTreeEntry {
  id: string;
  collection?: IExplorerEntryCollectionData;
  document?: IExplorerEntryDocumentData;
  // this is a collection id not an explorer id
  parentCollectionId: string | null;
  name: string;
  type: ExplorerEntryType;
  children: ExplorerTreeEntry[] = [];
  updatedAt: Date;

  constructor(id: string, parentCollectionId: string | null, name: string, type: ExplorerEntryType, updatedAt: Date) {
    this.id = id;
    this.parentCollectionId = parentCollectionId;
    this.name = name;
    this.type = type;
    this.updatedAt = updatedAt;
  }

  addChild(child: ExplorerTreeEntry) {
    this.children.push(child);
  }
}

function processRawDocument(rawDocument: RawDocument): IExplorerEntryDocumentData {
  return {
    ...rawDocument,
    documentType: getDocumentType(rawDocument.documentType),
    indexingStatus: getDocumentIndexingStatus(rawDocument.indexingStatus),
    jurisdiction: rawDocument.jurisdiction ? getJurisdiction(rawDocument.jurisdiction) : null,
    language: rawDocument.language ? getLanguage(rawDocument.language) : null,
    date: rawDocument.date ? new Date(rawDocument.date) : null,
    createdAt: new Date(rawDocument.createdAt),
    updatedAt: new Date(rawDocument.updatedAt),
  };
}

function processRawCollection(rawCollection: RawCollection): IExplorerEntryCollectionData {
  return {
    ...rawCollection,
    documentType: rawCollection.documentType ? getDocumentType(rawCollection.documentType) : null,
    jurisdiction: rawCollection.jurisdiction ? getJurisdiction(rawCollection.jurisdiction) : null,
    language: rawCollection.language ? getLanguage(rawCollection.language) : null,
    createdAt: new Date(rawCollection.createdAt),
    updatedAt: new Date(rawCollection.updatedAt),
  };
}

export class ExplorerTree {
  isSyncing = false;
  lastExplorerEntryUpdateAt = 0;
  lastDocumentUpdateAt = 0;
  lastCollectionUpdateAt = 0;
  lastExplorerEntryDeletedAt = 0;
  lastSyncKey = '';
  root = new ExplorerTreeEntry(
    EXPLORER_ROOT_ID,
    EXPLORER_ROOT_ID,
    'Root folder',
    ExplorerEntryType.Collection,
    new Date(),
  );
  entries = new Map<string, ExplorerTreeEntry>();
  collectionIdToExplorerId = new Map<string, string>();
  documentIdToExplorerId = new Map<string, string>();
  hasPendingChanges = false;
  hasPendingSync = false;

  pendingConnections = new Map<string, ExplorerTreeEntry[]>();

  private isFirstSync = true;
  private treeChangeEmitter = new Emitter<void>();
  onTreeChange = this.treeChangeEmitter.event;
  private syncStateChangeEmitter = new Emitter<boolean>();
  onSyncStateChange = this.syncStateChangeEmitter.event;

  private syncThrottle = new Throttle(() => {
    this.runSync();
  }, 100);

  constructor(private teamId: string) {
    const teamState = getTeamStates().getState(teamId);

    teamState.onExplorerEntriesUpdate(() => {
      this.syncThrottle.execute();
    });
    teamState.onDocumentsUpdate(() => {
      this.syncThrottle.execute();
    });
    teamState.onCollectionsUpdate(() => {
      this.syncThrottle.execute();
    });
    this.syncThrottle.execute();

    this.entries.set(EXPLORER_ROOT_ID, this.root);
  }

  emitChanges() {
    if (this.hasPendingChanges) {
      this.treeChangeEmitter.fire();
      this.hasPendingChanges = false;
    }
  }

  updateSyncState(newState: boolean) {
    this.isSyncing = newState;
    this.syncStateChangeEmitter.fire(newState);
  }

  private async runSync(): Promise<void> {
    if (this.isSyncing) {
      this.hasPendingSync = true;
      return;
    }

    this.updateSyncState(true);
    try {
      const syncKey = await this.fetchSyncKey();
      if (syncKey !== this.lastSyncKey) {
        console.log('Starting explorer tree sync...');
        let startTimeMs = Date.now();
        await this.fetchAllNewExplorerEntries();
        const duration = Date.now() - startTimeMs;

        // On the first sync we skip all deletes, document and collection updates that happened after the initial sync
        // We might need to keep in mind the amount of time it took to perform the initial sync?
        if (this.isFirstSync) {
          const otherUpdatedAtDate = this.lastExplorerEntryUpdateAt - duration;

          this.lastCollectionUpdateAt = otherUpdatedAtDate;
          this.lastDocumentUpdateAt = otherUpdatedAtDate;
          this.lastExplorerEntryDeletedAt = otherUpdatedAtDate;
        }

        await this.fetchAllDeletedExplorerEntries();
        await this.fetchAllNewDocuments();
        await this.fetchAllNewCollections();
        console.log('Explorer tree sync finished');

        this.isFirstSync = false;
        this.lastSyncKey = syncKey;

        this.emitChanges();
      } else {
        console.log('Skipping sync, sync key is the same');
      }
    } catch (err) {
      console.error(err);
    }
    this.updateSyncState(false);

    if (this.hasPendingSync) {
      this.hasPendingSync = false;
      this.syncThrottle.execute();
    }
  }

  updateDocument(rawDocument: RawDocument) {
    this.lastDocumentUpdateAt = Math.max(this.lastDocumentUpdateAt, new Date(rawDocument.updatedAt).getTime());

    const node = this.getDocumentNode(rawDocument.id);
    if (node) {
      const processedDoc = processRawDocument(rawDocument);
      if (node.document && node.document.updatedAt.toISOString() === processedDoc.updatedAt.toISOString()) {
        return;
      }

      node.document = processedDoc;
    }
    this.hasPendingChanges = true;
  }

  updateCollection(rawCollection: RawCollection) {
    this.lastCollectionUpdateAt = Math.max(this.lastCollectionUpdateAt, new Date(rawCollection.updatedAt).getTime());

    const node = this.getCollectionNode(rawCollection.id);
    if (node) {
      const processedCollection = processRawCollection(rawCollection);
      if (node.collection && node.collection.updatedAt.toISOString() === processedCollection.updatedAt.toISOString()) {
        return;
      }

      node.collection = processedCollection;
    }
    this.hasPendingChanges = true;
  }

  writeEntry(rawEntry: RawEntry) {
    this.lastExplorerEntryUpdateAt = Math.max(this.lastExplorerEntryUpdateAt, new Date(rawEntry.updatedAt).getTime());

    const parentCollectionId = rawEntry.parentCollection?.id;
    const entry = new ExplorerTreeEntry(
      rawEntry.id,
      parentCollectionId ?? null,
      rawEntry.name,
      getExplorerEntryType(rawEntry.type),
      new Date(rawEntry.updatedAt),
    );
    if (rawEntry.documentCollection?.id) {
      entry.collection = processRawCollection(rawEntry.documentCollection);
      this.collectionIdToExplorerId.set(rawEntry.documentCollection.id, rawEntry.id);
    }

    if (rawEntry.document?.id) {
      entry.document = processRawDocument(rawEntry.document);
      this.documentIdToExplorerId.set(rawEntry.document.id, rawEntry.id);
    }

    const existingEntry = this.entries.get(entry.id);
    if (existingEntry) {
      if (existingEntry.updatedAt.toISOString() === entry.updatedAt.toISOString()) {
        return;
      }

      existingEntry.name = entry.name;
      existingEntry.document = entry.document;
      existingEntry.collection = entry.collection;

      if (existingEntry.parentCollectionId !== entry.parentCollectionId) {
        const previousParent = existingEntry.parentCollectionId
          ? this.getCollectionNode(existingEntry.parentCollectionId)
          : this.root;
        if (previousParent) {
          previousParent.children = previousParent.children.filter((child) => child.id !== entry.id);
        }

        const newParent = entry.parentCollectionId ? this.getCollectionNode(entry.parentCollectionId) : this.root;
        if (newParent) {
          newParent.addChild(existingEntry);
        }

        existingEntry.parentCollectionId = entry.parentCollectionId;
      }
    } else {
      if (!parentCollectionId) {
        this.root.addChild(entry);
        this.entries.set(entry.id, entry);
      } else {
        const foundParent = this.getCollectionNode(parentCollectionId);
        if (foundParent) {
          foundParent.addChild(entry);
        } else {
          const pendingConnections = this.pendingConnections.get(parentCollectionId) ?? [];
          pendingConnections.push(entry);
          this.pendingConnections.set(parentCollectionId, pendingConnections);
        }

        this.entries.set(entry.id, entry);
      }

      if (entry.collection?.id && this.pendingConnections.has(entry.collection.id)) {
        for (const pendingChild of this.pendingConnections.get(entry.collection.id)!) {
          // handle entries that have been deleted in the meantime...
          if (!this.entries.has(pendingChild.id)) {
            continue;
          }

          entry.addChild(pendingChild);
        }

        this.pendingConnections.delete(entry.collection.id);
      }
    }

    this.hasPendingChanges = true;
  }

  deleteEntry(id: string, deletedAt: string) {
    this.lastExplorerEntryDeletedAt = Math.max(this.lastExplorerEntryDeletedAt, new Date(deletedAt).getTime());

    const foundEntry = this.entries.get(id);
    if (!foundEntry) {
      // console.warn('Trying to delete entry that does not exist', id);
      return;
    }

    if (foundEntry.parentCollectionId) {
      const parentEntry = this.getCollectionNode(foundEntry.parentCollectionId);
      if (parentEntry) {
        parentEntry.children = parentEntry.children.filter((child) => child !== foundEntry);
      } else {
        console.warn('Parent node not found when deleting entry', foundEntry.parentCollectionId);
      }
    } else {
      this.root.children = this.root.children.filter((child) => child !== foundEntry);
    }
    this.entries.delete(id);

    this.hasPendingChanges = true;
  }

  async fetchAllNewExplorerEntries(): Promise<void> {
    const since = new Date(this.lastExplorerEntryUpdateAt).toISOString();

    let cursor: string | undefined = undefined;
    while (true) {
      const entries = await this.fetchNewExplorerEntries(since, cursor);

      if (!entries.length || entries[entries.length - 1]?.id === cursor) {
        break;
      }

      for (const entry of entries) {
        this.writeEntry(entry);
        cursor = entry.id;
      }

      if (entries.length < PAGE_SIZE) {
        break;
      }
    }
  }

  async fetchNewExplorerEntries(since: string, cursor: string | undefined): Promise<RawEntry[]> {
    const searchQuery = new URLSearchParams();
    if (cursor) {
      searchQuery.set('id', cursor);
    }
    searchQuery.set('take', PAGE_SIZE.toString());
    searchQuery.set('since', since);
    const results = await fetchEndpointData<SyncExplorerEntriesResponseType>(
      `/api/v1/explorer-tree/sync-entries/${this.teamId}?${searchQuery.toString()}`,
    );
    return results.entries;
  }

  async fetchAllNewDocuments(): Promise<void> {
    const since = new Date(this.lastDocumentUpdateAt).toISOString();

    let cursor: string | undefined = undefined;
    while (true) {
      const entries = await this.fetchNewDocuments(since, cursor);

      if (!entries.length || entries[entries.length - 1]?.id === cursor) {
        break;
      }

      for (const entry of entries) {
        this.updateDocument(entry);
        cursor = entry.id;
      }

      if (entries.length < PAGE_SIZE) {
        break;
      }
    }
  }

  async fetchNewDocuments(since: string, cursor: string | undefined): Promise<RawDocument[]> {
    const searchQuery = new URLSearchParams();
    if (cursor) {
      searchQuery.set('id', cursor);
    }
    searchQuery.set('take', PAGE_SIZE.toString());
    searchQuery.set('since', since);
    const results = await fetchEndpointData<SyncDocumentsResponseType>(
      `/api/v1/explorer-tree/sync-documents/${this.teamId}?${searchQuery.toString()}`,
    );
    return results.documents;
  }

  async fetchAllNewCollections(): Promise<void> {
    const since = new Date(this.lastCollectionUpdateAt).toISOString();

    let cursor: string | undefined = undefined;
    while (true) {
      const entries = await this.fetchNewCollections(since, cursor);

      if (!entries.length || entries[entries.length - 1]?.id === cursor) {
        break;
      }

      for (const entry of entries) {
        this.updateCollection(entry);
        cursor = entry.id;
      }

      if (entries.length < PAGE_SIZE) {
        break;
      }
    }
  }

  async fetchNewCollections(since: string, cursor: string | undefined): Promise<RawCollection[]> {
    const searchQuery = new URLSearchParams();
    if (cursor) {
      searchQuery.set('id', cursor);
    }
    searchQuery.set('take', PAGE_SIZE.toString());
    searchQuery.set('since', since);
    const results = await fetchEndpointData<SyncCollectionsResponseType>(
      `/api/v1/explorer-tree/sync-collections/${this.teamId}?${searchQuery.toString()}`,
    );
    return results.collections;
  }

  private async fetchAllDeletedExplorerEntries(): Promise<void> {
    const since = new Date(this.lastExplorerEntryDeletedAt).toISOString();
    let cursor: string | undefined = undefined;
    while (true) {
      const entries = await this.fetchDeletedExplorerEntries(since, cursor);
      if (!entries.length || entries[entries.length - 1]?.id === cursor) {
        break;
      }

      for (const entry of entries) {
        this.deleteEntry(entry.entryId, entry.createdAt);
        cursor = entry.id;
      }

      if (entries.length < PAGE_SIZE) {
        break;
      }
    }
  }

  async fetchDeletedExplorerEntries(since: string, cursor: string | undefined): Promise<DeletedEntry[]> {
    const searchQuery = new URLSearchParams();
    if (cursor) {
      searchQuery.set('id', cursor);
    }
    searchQuery.set('take', PAGE_SIZE.toString());
    searchQuery.set('since', since);
    const results = await fetchEndpointData<DeletedExplorerEntriesResultType>(
      `/api/v1/explorer-tree/deleted-entries/${this.teamId}?${searchQuery.toString()}`,
    );
    return results.deletedEntries;
  }

  async fetchSyncKey() {
    const results = await fetchEndpointData<ExplorerSyncKeyResponseType>(
      `/api/v1/explorer-tree/sync-key/${this.teamId}`,
    );
    return results.syncKey ?? '';
  }

  getDocumentNode(documentId: string): ExplorerTreeEntry | undefined {
    const explorerId = this.documentIdToExplorerId.get(documentId);
    if (!explorerId) {
      return undefined;
    }
    return this.entries.get(explorerId);
  }

  getCollectionNode(collectionId: string): ExplorerTreeEntry | undefined {
    const explorerId = this.collectionIdToExplorerId.get(collectionId);
    if (!explorerId) {
      return undefined;
    }
    return this.entries.get(explorerId);
  }

  getChildCollections(collectionId: string): string[] {
    const explorerId = this.collectionIdToExplorerId.get(collectionId);
    if (!explorerId) {
      return [collectionId];
    }

    const explorerEntry = this.entries.get(explorerId);
    if (!explorerEntry) {
      return [collectionId];
    }

    const collectionIds = new Set<string>([collectionId]);
    for (const child of explorerEntry.children) {
      if (child.type === ExplorerEntryType.Collection) {
        if (!child.collection?.id || collectionIds.has(child.collection.id)) {
          continue;
        }

        collectionIds.add(child.collection.id);
        const childCollectionIds = this.getChildCollections(child.collection.id);
        for (const childCollectionId of childCollectionIds) {
          collectionIds.add(childCollectionId);
        }
      }
    }
    return [...collectionIds];
  }

  getChildDocuments(collectionId: string, includeNested: boolean = false): string[] {
    const explorerId = this.collectionIdToExplorerId.get(collectionId);
    if (!explorerId) {
      return [];
    }

    const explorerEntry = this.entries.get(explorerId);
    if (!explorerEntry) {
      return [];
    }

    const documentIds = new Set<string>();
    for (const child of explorerEntry.children) {
      if (child.type === ExplorerEntryType.Document) {
        if (!child.document?.id) {
          continue;
        }

        documentIds.add(child.document.id);
      } else if (includeNested && child.type === ExplorerEntryType.Collection) {
        if (!child.collection?.id) {
          continue;
        }

        const childDocumentIds = this.getChildDocuments(child.collection.id, true);
        for (const childDocumentId of childDocumentIds) {
          documentIds.add(childDocumentId);
        }
      }
    }
    return [...documentIds];
  }

  getPendingDocuments() {
    const pendingDocuments: ExplorerTreeEntry[] = [];
    for (const entry of this.entries) {
      if (entry[1].document && entry[1].document.indexingStatus !== DocumentIndexingStatus.Indexed) {
        pendingDocuments.push(entry[1]);
      }
    }
    return pendingDocuments;
  }
}

const existingExplorerTrees = new Map<string, ExplorerTree>();
export function getExplorerTreeInstance(teamId: string) {
  const tree = existingExplorerTrees.get(teamId) ?? new ExplorerTree(teamId);
  existingExplorerTrees.set(teamId, tree);
  return tree;
}
